import React, { useEffect, useState } from "react";
// import Logo from "../assets/logo-transparent.png";
import Logo from "../assets/landing-page/wriety-logo.svg";
// import Linkedin from "../assets/social-media/linkedin.png";
// import X from "../assets/social-media/twitter.png";
// import Instagram from "../assets/social-media/instagram.png";
// import Instagram from "../assets/brand-instagram.svg";
// import Phone from "../assets/phoneIcon.svg";
// import Mail from "../assets/mailIcon.svg";
// import Youtube from "../assets/brand-youtube.svg";
import { Link } from "react-router-dom";
import Facebook from "../assets/footer/facebook-footer.svg";
import Linkedin from "../assets/footer/linkedin-logo-with-bg.svg"
import X from "../assets/footer/x-footer.svg";
// import Linkedin from "../assets/brand-linkedin.svg";
// import Multimedia from "../assets/footer/media-footer.svg";
import Youtube from "../assets/footer/youtube-footer.svg";

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    // <div className="flex flex-col gap-[64px] s:items-center justify-center s:px-[25px] xmd:px-[44px] pt-[80px] pb-[12px] xl:px-[120px] bg-[#ffffff]">
    //   <div className="w-full flex s:flex-col xmd:flex-row justify-between items-start s:gap-[23px] xmd:gap-[15px] lg:gap-[30px] xl:gap-[60px] s:items-center xmd:items-start">
    //     <div className="flex flex-col s:gap-[5px] xmd:gap-[23px] s:items-center xmd:items-start">
    //       <h3 className="font-medium">Product</h3>
    //       <div className="flex flex-col gap-[9px] text-[#302C2C99] text-[15px]">
    //         <ul
    //           className={`${
    //             windowWidth < 880
    //               ? "flex flex-col items-center justify-center"
    //               : ""
    //           }`}
    //         >
    //           <li>OEM</li>
    //           <li>Advanced</li>
    //           <li>Android Box</li>
    //           <li>DMS</li>
    //           <li>Online Collaborations</li>
    //           <li>Productivity boards</li>
    //         </ul>
    //       </div>
    //     </div>

    //     {/* <div className="flex flex-col s:gap-[5px] xmd:gap-[23px] s:items-center xmd:items-start">
    //       <h3 className="font-medium">Use Cases</h3>
    //       <div className="flex flex-col gap-[9px] text-[#302C2C99] text-[15px]">
    //         <ul
    //           className={`${
    //             windowWidth < 880
    //               ? "flex flex-col items-center justify-center"
    //               : ""
    //           }`}
    //         >
    //           <li>Business VPN</li>
    //           <li>Remote Access</li>
    //           <li>Site-to-Site Networking</li>
    //           <li>Homelab</li>
    //           <li>Enterprise</li>
    //         </ul>
    //       </div>
    //     </div> */}

    //     <div className="flex flex-col s:gap-[5px] xmd:gap-[23px] s:items-center xmd:items-start">
    //       <h3 className="font-medium">Resources</h3>
    //       <div className="flex flex-col gap-[9px] text-[#302C2C99] text-[15px]">
    //         <ul
    //           className={` ${
    //             windowWidth < 880
    //               ? "flex flex-col items-center justify-center"
    //               : ""
    //           } `}
    //         >
    //           <li>Blog</li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className="flex flex-col s:gap-[5px] xmd:gap-[23px] s:items-center xmd:items-start">
    //       <h3 className="font-medium">Company</h3>
    //       <div className="flex flex-col gap-[9px] text-[#302C2C99] text-[15px]">
    //         <ul
    //           className={` ${
    //             windowWidth < 880
    //               ? "flex flex-col items-center justify-center"
    //               : ""
    //           } `}
    //         >
    //           <li>Company</li>
    //           <li>Careers</li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className="flex flex-col s:gap-[5px] xmd:gap-[23px] s:items-center xmd:items-start">
    //       <h3 className="font-medium">Help & Support</h3>
    //       <div className="flex flex-col gap-[9px] text-[#302C2C99] text-[15px]">
    //         <ul
    //           className={` ${
    //             windowWidth < 880
    //               ? "flex flex-col items-center justify-center"
    //               : ""
    //           } `}
    //         >
    //           <li>Support</li>
    //           <li>Sales</li>
    //           <li>Security</li>
    //           <li>Legal</li>
    //           <li>Open Source</li>
    //           <li>Changelog</li>
    //         </ul>
    //       </div>
    //     </div>

    //     {/* <div className="flex flex-col s:gap-[5px] xmd:gap-[23px] s:items-center xmd:items-start">
    //       <h3 className="font-medium">Learn</h3>
    //       <div className="flex flex-col gap-[9px] text-[#302C2C99] text-[15px]">
    //         <ul
    //           className={` ${
    //             windowWidth < 880
    //               ? "flex flex-col items-center justify-center"
    //               : ""
    //           } `}
    //         >
    //           <li>SSH Keys</li>
    //           <li>Docker SSH</li>
    //           <li>DevSecOps</li>
    //           <li>Multicloud</li>
    //           <li>NAT Traversal</li>
    //           <li>MagicDNS</li>
    //           <li>PAM</li>
    //           <li>PoLP</li>
    //           <li>All articles</li>
    //         </ul>
    //       </div>
    //     </div> */}
    //   </div>
    //   <div className="w-full bg-[#E5E5E5] h-[1px]"></div>
    //   {/*  s:gap-[40px] xmd:gap-[84px] */}
    //   <div className="w-full flex s:flex-col xmd:flex-row items-center xmd:items-start xmd:gap-[250px] justify-between s:gap-[80px]">
    //     <div className=" w-full flex flex-row s:justify-between xmd:justify-start s:gap-[45px] xmd:gap-[56px] ">
    //       <div className="flex flex-col gap-[35px]">
    //         {/* logo */}
    //         <img src={Logo} alt="logo" className="w-[10rem]" />
    //         <p className="text-[#302C2C99] text-[14px]">
    //           © 2024 3rdFlix Visual Effects Pvt., Ltd.
    //         </p>
    //       </div>
    //       <div className="flex flex-col gap-[14px]">
    //         <p className="text-[#302C2C99] text-[14px] border-b border-[#302C2C99] leading-[12px]">
    //           Terms of Service
    //         </p>
    //         <p className="text-[#302C2C99] text-[14px] border-b border-[#302C2C99] leading-[12px] mr-[20px]">
    //           Privacy Policy
    //         </p>
    //       </div>
    //     </div>

    //     {/* social links */}
    //     <div className="flex s:flex-row justify-center items-center gap-[8px]">
    //       <Link to="https://www.linkedin.com/company/wriety/">
    //         <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#000000] rounded-[9999px]">
    //           <img src={X} alt="X" />
    //         </button>
    //       </Link>
    //       <Link to="">
    //         <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#000000] rounded-[9999px]">
    //           <img src={Facebook} alt="Facebook" />
    //         </button>
    //       </Link>
    //       <Link to="https://www.linkedin.com/company/wriety/">
    //         <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#000000] rounded-[9999px]">
    //           <img src={Linkedin} alt="Linkedin" />
    //         </button>
    //       </Link>
    //       {/* <Link to="">
    //         <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#000000] rounded-[9999px]">
    //           <img src={Multimedia} alt="Multimedia" />
    //         </button>
    //       </Link> */}
    //       <Link to="https://www.youtube.com/channel/UCpcvUecJxIaKaGZwsvxAL7g">
    //         <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#000000] rounded-[9999px]">
    //           <img src={Youtube} alt="Youtube" />
    //         </button>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    <div className="flex py-[40px] items-center justify-center bg-[#FFFFFF]">
      <div className="flex lg:flex-row md:flex-row sm:flex-col-reverse flex-col-reverse lg:gap-[130px] md:gap-[100px] sm:gap-[20px] gap-[20px]  lg:py-[30px] md:py-[30px] sm:py-[20px] lg:px-[150px] md:px-[40px] sm:px-[30px] px-[30px] items-center justify-start w-[1570px]">
        <div
          className="flex flex-col lg:gap-[125px] md:gap-[100px] sm:gap-[20px] gap-[20px] items-start lg:items-start md:items-start sm:items-start sm:items-center text-center lg:text-left md:text-left sm:text-center lg:justify-start md:justify-start sm:justify-center justify-start lg:pt-[20px] md:pt-[0px] sm:pt-[40px] pt-[20px]">
          
          <Link to="" className="flex w-full s:justify-center sm:justify-start">
  <img src={Logo} alt="logo" className="w-40" />
</Link>
          <div className="flex flex-col gap-[25px] lg:items-start md:items-start sm:items-start sm:items-center">
            {/* social links */}
            <div className="flex sm:flex-row justify-center items-center gap-[8px] lg:items-start">
              {/* <a
                href="https://www.linkedin.com/company/wriety/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#000000] rounded-[9999px]">
                  <img src={X} alt="X" />
                </button>
              </a> */}
              {/* <a to="">
                <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#302C2C] rounded-[9999px]">
                  <img src={Facebook} alt="Facebook" />
                </button>
              </a> */}
              <a
                href="https://www.linkedin.com/company/wriety/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#302C2C] rounded-[9999px]">
                  <img src={Linkedin} alt="Linkedin" />
                </button>
              </a>
              <a
                href="https://www.youtube.com/channel/UCpcvUecJxIaKaGZwsvxAL7g"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className=" flex items-center justify-center w-[34px] h-[34px] bg-[#302C2C] rounded-[9999px]">
                  <img src={Youtube} alt="Youtube" />
                </button>
              </a>
            </div>
            <p className="text-[#302C2C99] text-[14px]">
              © 2024 3rdFlix Visual Effects Private Limited
            </p>
          </div>
        </div>


        <div className="w-[1px] bg-[#E5E5E5] h-[248px] lg:block md:block sm:hidden hidden"></div>
        <div className="flex flex-col lg:flex-row md:flex-row sm:flex-col justify-between lg:items-start md:items-start sm:items-center lg:text-left md:text-left sm:text-center items-center text-center	 lg:w-[50%] md:w-[50%] sm:w-[75%] w-[50%]">
          <div className="flex flex-col gap-[20px] lg:pt-[0px] lg:pt-[20px] md:pt-[0px] sm:pt-[40px] pt-[20px] lg:items-start md:items-start sm:items-center lg:text-left md:text-left sm:text-center">
            <p className="text-[#302C2C]">Product</p>
            <ul className="text-[#302C2C99] gap-[10px] flex flex-col">
            <Link
                to="advanced"
                className="hover:no-underline hover:text-inherit"
              >
                Advanced
              </Link>
              <Link to="oem" className="hover:no-underline hover:text-inherit">
                OEM
              </Link>
              <Link
                to="aiapi"
                className="hover:no-underline hover:text-inherit"
              >
                AI API
              </Link>
              <Link
                to="android"
                className="hover:no-underline hover:text-inherit"
              >
                Android Box
              </Link>
              <Link to="dms" className="hover:no-underline hover:text-inherit">
                DMS
              </Link>
              <Link
                to="online-collaborations"
                className="hover:no-underline hover:text-inherit"
              >
                Online Collaborations
              </Link>
              <Link
                to="productivity"
                className="hover:no-underline hover:text-inherit"
              >
                Productivity boards
              </Link>
              
            </ul>
          </div>
          <div className="flex flex-col gap-[20px] lg:pt-[0px] lg:pt-[20px] md:pt-[0px] sm:pt-[40px] pt-[20px] lg:items-start md:items-start sm:items-center lg:text-left md:text-left sm:text-center">
            <p className="text-[#302C2C]">Resources</p>
            <ul className="text-[#302C2C99] gap-[10px] flex flex-col">
              
              
              <Link
                to="blogs"
                className="hover:no-underline hover:text-inherit"
              >
                Blog
              </Link>
              <Link
                to="market-place"
                className="hover:no-underline hover:text-inherit"
              >
                MarketPlace
              </Link>
              <Link
                to="add-ons-1"
                className="hover:no-underline hover:text-inherit"
              >
                Add-ons
              </Link>

              <Link
                to="terms-and-conditions"
                className="hover:no-underline hover:text-inherit"
              >
                Terms of Service
              </Link>
              <Link
                to="privacy-policy"
                className="hover:no-underline hover:text-inherit"
              >
                Privacy Policy
              </Link>
              <Link to="company" className="hover:no-underline hover:text-inherit">
                Company
              </Link>
<a className="hover:no-underline hover:text-inherit" href=" https://wriety.tawk.help/" target="_blank"> Knowledge Center</a>

            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
