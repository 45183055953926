import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

let messageIcon = null;

const FeaturesAnimation = (props) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const reqSvgs = require.context ("../../../assets/project-menu/aiapi/features", true, /\.svg$/ )

  const svgs = reqSvgs.keys().map ((path)=> ({ path, url: reqSvgs ( path ) }) );
  function splitToNChunks(array, n) {
      let result = [];
      for (let i = n; i > 0; i--) {
          result.push(array.splice(0, Math.ceil(array.length / i)));
      }
      return result;
  }

   let svgArrays = splitToNChunks([...svgs], 4);

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  {let firstArrLen = svgArrays[0].length;}
  return (
    <div className="flex items-center justify-center w-full ">
    <div className="w-full max-w-[1570px] flex s:flex-col text-[#ffffff] ">
     
    <div className="slider-container rounded-[12px] md:py-[40px] s:py-[40px] w-full lg:h-[520px]"  id="slider-container" >
      
       <Marquee direction="right" className="md:pt-[10px] md:pb-[10px] s:pt-[10px]">
     
      {svgArrays[0].map(item =>
        <div className="slide-item">
          <div className="blog_post">
            <div className="">
             
            <img
                    src={item.url}
                    alt="message"
                    className=""
                  />
            </div>
          </div>
        </div>
        )} 
       </Marquee>


        <Marquee direction="left" className="md:pt-[10px] md:pb-[10px] s:pt-[10px]">
        {svgArrays[1].map(item =>
        <div className="slide-item">
          <div className="blog_post">
            <div className="">
             
            <img
                    src={item.url}
                    alt="message"
                    className=""
                  />
            </div>
          </div>
        </div>
        )} 
      </Marquee>
      <Marquee direction="right" className="md:pt-[10px] md:pb-[10px] s:pt-[10px]">
        {svgArrays[2].map(item =>
        <div className="slide-item">
          <div className="blog_post">
            <div className="">
             
            <img
                    src={item.url}
                    alt="message"
                    className=""
                  />
            </div>
          </div>
        </div>
        )} 
      </Marquee>
      {innerWidth > 768 && (
        
        <Marquee direction="left" className="md:pt-[10px] md:pb-[10px] s:pt-[10px]">  
         {svgArrays[3].map(item =>
        <div className="slide-item">
          <div className="blog_post">
            <div className="">
             
            <img
                    src={item.url}
                    alt="message"
                    className=""
                  />
            </div>
          </div>
        </div>
        )} 

        </Marquee>)}
      <div
        className=" d-none bg-[#302C2C] absolute w-[96%] s:w-[300px] xs:w-[400px] lg:w-[500px]  h-[50px] lg:h-[80px] text-[18px]  lg:text-[33px] left-0 right-0 bottom-0 top-0 m-auto text-[#ffffff] flex items-center justify-center shadow-box rounded-[14px]"
        style={{ zIndex: "4000" }}
      >
        we couldn't have said more
      </div>
    </div>
    </div>
    </div>
  );
};

export default FeaturesAnimation;
