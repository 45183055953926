import React, { useState, useEffect } from "react";
import whiteBoardImg from "../../../assets/project-menu/oem/whiteboard-image.png";
import downArrow from "../../../assets/project-menu/oem/down-arrow.svg";
import tableImage1 from "../../../assets/project-menu/oem/oem-animation-first-img.png"; // First image
import tableImage2 from "../../../assets/project-menu/oem/oem-animation-second-img.png"; // Second image
import tableImage3 from "../../../assets/project-menu/oem/oem-animation-third-img.png"; // Third image
import verticalDivider from "../../../assets/project-menu/oem/VerticalDivider.svg";
import whiteRightArrow from "../../../assets/project-menu/oem/white-right-arrow.svg";
import secondCardImage from "../../../assets/project-menu/oem/second-card-image.png";
import oemSpec from "../../../assets/project-menu/oem/OEM SpecSheet.pdf";
import oemBrouchure from "../../../assets/project-menu/oem/OEM Brochure.pdf";
import blackRightArrow from "../../../assets/project-menu/oem/blackRightArrow.svg";
import "./OemPage.css";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import DownloadButton from '../../DownloadButton';
const OemPage = () => {
  const [imageSrc, setImageSrc] = useState(tableImage1);
  const [isFixed, setIsFixed] = useState(false);
  const [isFirstContainerCross, setIsFirstContainerCross] = useState(false);
  const [isSecondContainerCross, setIsSecondContainerCross] = useState(false);
  const [isThirdContainerCross, setIsThirdContainerCross] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isHoverContactSales, setIsHoverContactSales] = useState(false);
  const [isStickyEnabled, setIsStickyEnabled] = useState(true);

  useEffect(() => {
    const headerHeight = document.getElementById("navbar")?.offsetHeight;

    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    const handleScroll = () => {
      if (innerWidth > 880) {
        const animationSection = document.getElementById("animation");
        const firstContainer = document.getElementById(
          "first-container-circle"
        );
        const secondContainer = document.getElementById(
          "second-container-circle"
        );
        const thirdContainer = document.getElementById("third-container");
        const imageElement = document.getElementById("image-element");

        const animationSectionRect = animationSection.getBoundingClientRect();
        const firstContainerRect = firstContainer.getBoundingClientRect();
        const secondContainerRect = secondContainer.getBoundingClientRect();
        const thirdContainerRect = thirdContainer.getBoundingClientRect();
        const imageElementRect = imageElement.getBoundingClientRect();

        console.log(imageElementRect.bottom, thirdContainerRect.bottom);

        const isCrossingHeader = animationSectionRect.top + 60 <= headerHeight;
        const isfirstContainerCrossing =
          firstContainerRect.top - 40 <= headerHeight;
        const issecondContainerCrossing =
          secondContainerRect.top - 40 <= headerHeight;
        let isthirdContainerCrossing;
        if (!isThirdContainerCross)
          isthirdContainerCrossing =
            thirdContainerRect.bottom <= imageElementRect.bottom;
        else
          isthirdContainerCrossing =
            thirdContainerRect.top + 60 <= headerHeight;

        setIsFixed(isCrossingHeader && !isthirdContainerCrossing);
        setIsFirstContainerCross(isfirstContainerCrossing);
        setIsSecondContainerCross(issecondContainerCrossing);
        setIsThirdContainerCross(isthirdContainerCrossing);

        if (!isFirstContainerCross && !isSecondContainerCross) {
          setImageSrc(tableImage1);
        } else if (isFirstContainerCross && !isSecondContainerCross) {
          setImageSrc(tableImage2);
        } else if (isFirstContainerCross && isSecondContainerCross) {
          setImageSrc(tableImage3);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      {/* s:pt-[130px] s:pb-[80px]  s:px-[27px] xmd:px-[120px]*/}
      <div className="flex items-center justify-center bg-[#34495E] w-full ">
      <div className="flex flex-col lg:flex-row w-full h-full bg-[#34495E] text-[#ffffff] lg:gap-[185px]">
  <div className="lg:max-w-[450px] w-full lg:w-1/2 flex flex-col gap-[22px] p-[20px] lg:p-[7px] xl:p-[0px] ml-auto my-auto g:pb-[0px] lg:pt-[0px] pt-[80px] pb-[40px]">
    <h1 className="text-[40px] s:text-[43px] s:leading-[54px] font-medium">
      OEM WhiteBoard with Offline AI
    </h1>
    <p className="text-[16px] text-[#FFFFFFB2]">
      Bring the power of AI to your OEM whiteboard. PIP features to annotate on the dashboard while presenting using the whiteboard. Go beyond traditional whiteboard with tools cutting across education and corporate use cases.
    </p>
    <div className="flex flex-row gap-[22px]">
      <a
        href={oemSpec}
        download="OEM Spec Sheet.pdf"
        className="bg-[#ffffff] px-[18px] py-[9px] gap-[10px] rounded-[6px] flex flex-row items-center hover:no-underline hover-container"
      >
        <p className="text-[#302C2C]">Spec Sheet</p>
        <div className="flex flex-row gap-[10px] relative overflow-hidden">
          <span className="absolute initial-slide-in">
            <img
              src={blackRightArrow}
              alt="arrow"
              className="w-[14px] h-[13px]"
            />
          </span>
          <span className="initial-slide-out">
            <img
              src={blackRightArrow}
              alt="arrow"
              className="w-[14px] h-[13px]"
            />
          </span>
        </div>
      </a>
      <button className="border-2 rounded-[8px] px-[15px] py-[7px]">
        Contact sales
      </button>
    </div>
    <DownloadButton DownloadFileName="OEM Brochure.pdf" brocherDownloadLink= {oemBrouchure} downArrow={downArrow} />
   
  </div>
  <div className="lg:w-1/2 lg:w-[50%] w-[100%]">
    <img
      src={whiteBoardImg}
      alt="White-board"
      className="w-full h-full object-cover"
    />
  </div>
</div>


      </div>

      {/* animation */}
      {innerWidth > 880 && (
        <div
          id="animation"
          className={`w-full max-w-full h-full flex items-start justify-between py-[60px] gap-[20px] relative px-[20px] xl:px-[0px] text-[#000000]`}
        >
          <div className="text-container flex flex-col gap-[25px] xmd:w-[400px] xl:w-[450px]">
            <h1 className="text-[34px] font-medium">
              Designed for factory install with offline AI and annotate over
              anything capability
            </h1>
            {/* first container */}
            <div id="first-container" className={`flex flex-col gap-[8px] `}>
              <div className="flex flex-col gap-[2px] relative">
                <div className="bg-[#34495E] rounded-[9999px] w-[26px] h-[26px] text-[#FFFFFF] flex items-center justify-center">
                  1
                </div>
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] h-[264px]"
                />
                <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div>
              </div>
              <div className="flex flex-col gap-[16px] pt-[8px] relative pr-[136px]">
                <h3 className="text-[20px] font-medium">
                On device AI supporting amazing array of functionality
                </h3>
                <p className="text-[#00000099] text-[14px]">
                Supports 74 languages, math formula, arithmetic answers,
                  drawing recognition, flowsheets, content aware writing and
                  much more.
                </p>
              </div>
            </div>
            {/* second container */}
            <div id="second-container" className={`flex flex-col gap-[8px]`}>
              <div className="flex flex-col gap-[2px] relative">
                <div className="bg-[#34495E] rounded-[9999px] w-[26px] h-[26px] text-[#FFFFFF] flex items-center justify-center">
                  2
                </div>
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] h-[266px]"
                />
                <div
                  id="second-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div>
              </div>
              <div className="flex flex-col gap-[16px] pt-[8px] pr-[136px]">
                <h3 className="text-[20px] font-medium">
                Peer to Peer Screen Sharing within WIFI network
                </h3>
                <p className="text-[#302C2CA6] text-[14px]">
                Use P2P screen sharing within the same WiFi network from any
                  device or browser to engage with you participants. Unlimited
                  screen shares simultaneously with option to flip through them
                  or view nine screen shares at a time.
                </p>
              </div>
            </div>
            {/* third container */}
            <div id="third-container" className={`flex flex-col gap-[8px]`}>
              <div className="flex flex-col gap-[2px] relative">
                <div className="bg-[#34495E] rounded-[9999px] w-[26px] h-[26px] text-[#FFFFFF] flex items-center justify-center">
                  3
                </div>
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] h-[266px]"
                />
                <div
                  id="third-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div>
              </div>
              <div className="flex flex-col gap-[16px] pt-[8px] pr-[136px]" >
                <h3 className="text-[20px] font-medium">
                Makes your device a canvas using Picture in Picture mode
                </h3>
                <p className="text-[#00000099] text-[14px]">
                Present anything and annotate anything while using OEM
                  whiteboard. You have the power of the whiteboard annotation
                  capabilities across any application on your panel or device.
                </p>
              </div>
            </div>
          </div>

          <div className="relative w-full max-w-[600px] min-w-[300px]">
            <Sticky enabled={isStickyEnabled} top={60} bottomBoundary="#animation">
              <div className="image-container1 sticky top-0 flex flex-col items-center justify-center">
                <img
                  src={imageSrc}
                  alt="Changing animation"
                  id="image-element"
                  className="transition-all duration-300 ease-in-out w-[625px] h-auto"
                />
              </div>
            </Sticky>
            
          </div>
        </div>
      )}

{innerWidth <= 880 && (
        <div className="w-full h-full  flex flex-col items-center py-[40px]  lg:py-[100px] s:px-[27px] xxs:px-[60px] justify-center gap-[66px]">
          <div className="flex flex-col gap-[25px]">
            <h1 className="s:text-[28px] md:text-[34px] font-medium text-center">
              Designed for factory install with offline AI and annotate over
              anything capability
            </h1>
            {/* first container */}
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <img
                src={tableImage1}
                alt="tableImage"
                className="rounded-[14px] xxs:w-[450px]"
              />
              <div className="flex flex-col gap-[2px] relative">
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] s:h-[164px] xxs:h-[200px]"
                />
                {/* <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div> */}
              </div>
              <div className="flex flex-col gap-[8px] ">
                <h3 className="font-normal text-[24px] text-[#302C2C] text-center">
                  On device AI supporting amazing array of functionality
                </h3>
                <p className="text-[15px] text-[#302C2CA6] text-center">
                  Supports 74 languages, math formula, arithmetic answers,
                  drawing recognition, flowsheets, content aware writing and
                  much more.
                </p>
              </div>
            </div>
            {/* second container */}
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <img
                src={tableImage2}
                alt="tableImage"
                className="rounded-[14px] xxs:w-[450px]"
              />
              <div className="flex flex-col gap-[2px] relative">
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] s:h-[164px] xxs:h-[200px]"
                />
                {/* <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div> */}
              </div>
              <div className="flex flex-col gap-[8px] ">
                <h3 className="font-normal text-[24px] text-[#302C2C] text-center">
                  Peer to Peer Screen Sharing within WIFI network
                </h3>
                <p className="text-[15px] text-[#302C2CA6] text-center">
                  Use P2P screen sharing within the same WiFi network from any
                  device or browser to engage with you participants. Unlimited
                  screen shares simultaneously with option to flip through them
                  or view nine screen shares at a time.
                </p>
              </div>
            </div>
            {/* third container */}
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <img
                src={tableImage3}
                alt="tableImage"
                className="rounded-[14px] xxs:w-[450px]"
              />
              <div className="flex flex-col gap-[2px] relative">
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] s:h-[164px] xxs:h-[200px]"
                />
                {/* <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div> */}
              </div>
              <div className="flex flex-col gap-[8px] ">
                <h3 className="font-normal text-[24px] text-[#302C2C] text-center">
                  Makes your device a canvas using Picture in Picture mode
                </h3>
                <p className="text-[15px] text-[#302C2CA6] text-center">
                  Present anything and annotate anything while using OEM
                  whiteboard. You have the power of the whiteboard annotation
                  capabilities across any application on your panel or device.
                </p>
              </div>
            </div>
            </div>
            </div>
                )};

 {/* cards */}
 <div className="flex items-center justify-center w-full ">
        <div className="w-full max-w-[1570px] flex s:flex-col xmd:flex-row gap-[20px] s:px-[25px] sm:px-[50px] xmd:px-[30px] s:py-[30px] sm:py-[45px] xmd:py-[60px] text-[#ffffff] ">
          {/* first card */}
          <div className="flex flex-col s:w-full xmd:w-4/6 h-full rounded-[12px] overflow-hidden">
            <div className="bg-[#092037] pt-[40px] pb-[86px] s:pb-[32px] sm:pb-[50px] md:h-[380px]">
            <h1 className="font-medium text-[34px] s:text-[19px] sm:text-[46px] md:px-[40px] px-[20px]">
                Ready to Take your Interactive Flat Panel Display experience to
                next level?
              </h1>
            </div>
            <div className="py-[30px] bg-[#34495E] px-[70px] s:px-[25px] sm:px-[40px]">
              <p className="text-[17px] ">Schedule a demo</p>
              <div className="w-[160px] mt-[35px] flex flex-row gap-[9px]">
              <div
                      className="flex flex-col gap-[5px] justify-center"
                      style={{ alignItems: "flex-start" }}
                      onMouseEnter={() => setIsHoverContactSales(true)}
                      onMouseLeave={() => setIsHoverContactSales(false)}
                    >
                      <Link
                        to="/contact-us"
                        className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                      >
                        <p className="leading-[12px] font-semibold">
                          Contact Sales
                        </p>
                        <span
                          className={`${isHoverContactSales ? "bounce-x" : ""}`}
                        >
                          <img
                            src={whiteRightArrow}
                            alt="Arrow"
                            className="w-[21px] h-[12px]"
                          />
                        </span>
                      </Link>
                      <div
                        className={`h-[2px] bg-[#fff] justify-end ${
                          isHoverContactSales
                            ? "w-[80%] transition-all"
                            : "w-[100%] transition-all"
                        }`}
                        style={{ willChange: "transform" }}
                      ></div>
                    </div>
              </div>
            </div>
          </div>
          {/* second card */}
          <div className="w-full bg-cover rounded-[12px] lg:h-[522px]">
            <img
              src={secondCardImage}
              alt="second card"
              className="w-full s:h-full "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OemPage;
