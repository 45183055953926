import React, { useState } from "react";
import PrimaryButton from "./PrimaryButton";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo.png";
import Cross from "../assets/cross.svg";
import ArrowButton from "./ArrowButton";

const MobileNav = ({ setOpenMobileMenu }) => {
  let menuTimeout;
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [hoveredLabel, setHoveredLabel] = useState("");

  const handleHoveredLabel = (label) => {
    setHoveredLabel(label);
  };

  const handleProductMenu = () => {
    if (openProductMenu === false) setOpenProductMenu(true);
    else {
      setOpenProductMenu(false);
    }
  };

  const handleProductMenuEnter = () => {
    // console.log("handleProductmenu");
    clearTimeout(menuTimeout); // Clear any existing timeout
    setOpenProductMenu(true);
  };

  const handleProductMenuLeave = () => {
    menuTimeout = setTimeout(() => {
      setOpenProductMenu(false);
    }, 200); // Adjust the delay time as needed
  };

  return (
    <div className="fixed z-50 w-screen h-screen top-0 left-0 bg-white lg:p-12 p-4 flex flex-col gap-10">
      <div className="flex justify-between items-center">
        <Link to="/">
          <img src={Logo} alt="logo" className="md:w-[13.5rem] w-40" />
        </Link>

        <button onClick={() => setOpenMobileMenu(false)}>
          <img className="xmd:hidden w-6 h-6" src={Cross} />
        </button>
      </div>

      <div className="flex flex-col text-[#050038] text-lg gap-4">
        {/* <Link to=""> */}
        <button
          className="text-left"
          onClick={handleProductMenu}
          onMouseEnter={handleProductMenuEnter}
          onMouseLeave={handleProductMenuLeave}
        >
          Products
        </button>
        {openProductMenu && (
          <div
            className={`top-[147px] rounded-2xl will-change-transform fixed bg-[#FFFFFF] shadow-xl `}
            style={{ zIndex: "9999" }}
            onMouseEnter={handleProductMenuEnter}
            onMouseLeave={handleProductMenuLeave}
          >
            <div className="flex flex-row gap-[35px] px-[10px] py-[23px]">
              <div
                className="flex flex-col justify-center items-start xmd:gap-[5px] lg:gap-[10px]"
                style={{ alignItems: "flex-start" }}
              >
                <ArrowButton
                  setOpenMobileMenu={setOpenMobileMenu}
                  label="Advanced"
                  labelHandle={handleHoveredLabel}
                  currHoverLabel={hoveredLabel}
                />
                <ArrowButton
                  setOpenMobileMenu={setOpenMobileMenu}
                  label="OEM"
                  labelHandle={handleHoveredLabel}
                  currHoverLabel={hoveredLabel}
                />
                 <ArrowButton
                    setOpenMobileMenu={setOpenMobileMenu}
                    label="AI API"
                    labelHandle={handleHoveredLabel}
                    currHoverLabel={hoveredLabel}
                  />
                <ArrowButton
                  setOpenMobileMenu={setOpenMobileMenu}
                  label="Android Box"
                  labelHandle={handleHoveredLabel}
                  currHoverLabel={hoveredLabel}
                />
                <ArrowButton
                  setOpenMobileMenu={setOpenMobileMenu}
                  label="DMS"
                  labelHandle={handleHoveredLabel}
                  currHoverLabel={hoveredLabel}
                />
                <ArrowButton
                  setOpenMobileMenu={setOpenMobileMenu}
                  label="Online collaborations"
                  labelHandle={handleHoveredLabel}
                  currHoverLabel={hoveredLabel}
                />
                <ArrowButton
                  setOpenMobileMenu={setOpenMobileMenu}
                  label="Productivity Board"
                  labelHandle={handleHoveredLabel}
                  currHoverLabel={hoveredLabel}
                />
              </div>
              {/* <Cards label={hoveredLabel} /> */}
            </div>
          </div>
        )}
        {/* </Link> */}
        {/* <Link to="wriety-mdm">
          <button onClick={() => setOpenMobileMenu(false)}>WrietyMDM</button>
        </Link> */}
        {/* <Link to="market-place">
          <button onClick={() => setOpenMobileMenu(false)}>Market Place</button>
        </Link> */}
        <Link to="add-ons-1">
          <button onClick={() => setOpenMobileMenu(false)}>
            Add on Features
          </button>
        </Link>
         <Link to="market-place">
         <button onClick={() => setOpenMobileMenu(false)}>
            MarketPlace
          </button>
          </Link>
          <Link  to="blogs" >
          <button onClick={() => setOpenMobileMenu(false)}>
           Blog
          </button>
          </Link>
          <Link to="company">
              
          <button onClick={() => setOpenMobileMenu(false)}>
           Company
          </button>
            </Link>
        <Link to="contact-us">
          <button onClick={() => setOpenMobileMenu(false)}>
            Support
          </button>
        </Link>
        {/* <Link to="about">
          <button onClick={() => setOpenMobileMenu(false)}>About</button>
        </Link> */}
        {/* <Link to="pricing">
          <button onClick={() => setOpenMobileMenu(false)}>Pricing</button>
        </Link> */}
      </div>

      {/* <PrimaryButton link={"contact-us"} title="Contact Us" /> */}
      <a href="https://app.wriety.com/web" target="_blank" rel="noreferrer">
        <button className="bg-[#000000] py-[9px] px-[18px] rounded-[8px] text-[#FFFFFF] max-w-max">
          Get started
        </button>
      </a>
    </div>
  );
};

export default MobileNav;
