function DownloadButton(props){
    let downArrow = props.downArrow ? props.downArrow : "";
    let brocherDownloadLink = props.brocherDownloadLink ? props.brocherDownloadLink : "";
    let DownloadFileName = props.DownloadFileName ? props.DownloadFileName : "Brocher.pdf";
    return(
    <button
     className="w-[175px] mt-[35px] border-b-2 border-[#CCCCCC] flex flex-row gap-[9px] hover:no-underline hover:text-inherit" >
      <p className="leading-[22px] text-[16px]" onClick={()=>handleClick(brocherDownloadLink, DownloadFileName)}>Download Brochure</p>
      <img src={downArrow} alt="Arrow" />
    </button>
    )
}
function handleClick(brocherDownloadLink, DownloadFileName){
  if(brocherDownloadLink){
    const url = brocherDownloadLink;
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.download = DownloadFileName;
    link.click();
  }else{
    alert('Coming Soon...');
  }
}
export default DownloadButton;
