import { useState, useEffect } from "react";

import downArrow from "../assets/downArrow.svg";
import rightArrow from "../assets/rightArrowIcon.svg";
import { Link } from "react-router-dom";

const ArrowButton = ({ label, labelHandle, currHoverLabel, setOpenMobileMenu }) => {
  const [pageLink, setPageLink] = useState("");
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (label === "OEM") {
      setPageLink("oem");
    } else if (label === "Advanced") {
      setPageLink("advanced");
    } else if (label === "Android Box") {
      setPageLink("android");
    } else if (label === "DMS") {
      setPageLink("dms");
    } else if (label === "Online collaborations") {
      setPageLink("online-collaborations");
    } else if (label === "Productivity Board") {
      setPageLink("productivity");
    } else if (label === "AI API") {
      setPageLink("aiapi");
    }
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
    labelHandle(label);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Link
      to={pageLink}
      className={`rounded-[8px] flex text-[#302C2C] font-[500] flex-row items-center justify-between xmd:w-[155px] lg:w-[190px] mb-[2px] px-[14px] py-[4px] hover:text-inherit hover:no-underline ${
        currHoverLabel === label || hovered ? "bg-[#ebeaea] " : ""
      }`}
      onClick={() => setOpenMobileMenu(false)}
      onMouseEnter={() => handleMouseEnter(label)}
      onMouseLeave={() => handleMouseLeave()}
    >
      <p className="text-[#302C2C] font-[500]  xmd:text-[10px] lg:text-[16px]">{label}</p>
      {/* <img
        src={hovered || currHoverLabel === label ? rightArrow : downArrow}
        alt={hovered || currHoverLabel === label ? "rightArrow" : "downArrow"}
        className="h-[10px] w-[10px]"
      /> */}
    </Link>
  );
};

export default ArrowButton;
