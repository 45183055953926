import React, { useState, useEffect } from "react";
import rightArrow from "../../../assets/project-menu/oem/blackRightArrow.svg";
import whiteBoardImg from "../../../assets/project-menu/online-collab/online-collab-first-img.png";
import downArrow from "../../../assets/project-menu/oem/down-arrow.svg";
import tableImage1 from "../../../assets/project-menu/online-collab/online-first-animation.png"; // First image
import tableImage2 from "../../../assets/project-menu/online-collab/online-second-animation.png"; // Second image
import tableImage3 from "../../../assets/project-menu/online-collab/online-third-animation.png"; // Third image
import verticalDivider from "../../../assets/project-menu/oem/VerticalDivider.svg";
import whiteRightArrow from "../../../assets/project-menu/oem/white-right-arrow.svg";
import secondCardImage from "../../../assets/project-menu/online-collab/second-card-image.png";
import "./OnlineCollab.css";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";
import DownloadButton from '../../DownloadButton';
import SpecsBtn from '../../SpecSection';
import onlineCollabBrochure from "../../../assets/project-menu/advanced/Advanced-Brochure.pdf";

const OnlineCollab = () => {
  const [imageSrc, setImageSrc] = useState(tableImage1);
  const [isFixed, setIsFixed] = useState(false);
  const [isFirstContainerCross, setIsFirstContainerCross] = useState(false);
  const [isSecondContainerCross, setIsSecondContainerCross] = useState(false);
  const [isThirdContainerCross, setIsThirdContainerCross] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isHoverContactSales, setIsHoverContactSales] = useState(false);
  const [isStickyEnabled, setIsStickyEnabled] = useState(true);
  
  useEffect(() => {
    const headerHeight = document.getElementById("navbar")?.offsetHeight;

    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    const handleScroll = () => {
      if (innerWidth > 880) {
        const animationSection = document.getElementById("animation");
        const firstContainer = document.getElementById(
          "first-container-circle"
        );
        const secondContainer = document.getElementById(
          "second-container-circle"
        );
        const thirdContainer = document.getElementById("third-container");
        const imageElement = document.getElementById("image-element");

        const animationSectionRect = animationSection.getBoundingClientRect();
        const firstContainerRect = firstContainer.getBoundingClientRect();
        const secondContainerRect = secondContainer.getBoundingClientRect();
        const thirdContainerRect = thirdContainer.getBoundingClientRect();
        const imageElementRect = imageElement.getBoundingClientRect();

        console.log(imageElementRect.bottom, thirdContainerRect.bottom);

        const isCrossingHeader = animationSectionRect.top + 60 <= headerHeight;
        const isfirstContainerCrossing =
          firstContainerRect.top - 40 <= headerHeight;
        const issecondContainerCrossing =
          secondContainerRect.top - 40 <= headerHeight;
        let isthirdContainerCrossing;
        if (!isThirdContainerCross)
          isthirdContainerCrossing =
            thirdContainerRect.bottom <= imageElementRect.bottom;
        else
          isthirdContainerCrossing =
            thirdContainerRect.top + 60 <= headerHeight;

        setIsFixed(isCrossingHeader && !isthirdContainerCrossing);
        setIsFirstContainerCross(isfirstContainerCrossing);
        setIsSecondContainerCross(issecondContainerCrossing);
        setIsThirdContainerCross(isthirdContainerCrossing);

        if (!isFirstContainerCross && !isSecondContainerCross) {
          setImageSrc(tableImage1);
        } else if (isFirstContainerCross && !isSecondContainerCross) {
          setImageSrc(tableImage2);
        } else if (isFirstContainerCross && isSecondContainerCross) {
          setImageSrc(tableImage3);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
    };
  });

  return (
    <div>
      {/* s:pt-[130px] s:pb-[80px]  s:px-[27px] xmd:px-[120px]*/}
      <div className="flex items-center justify-center  bg-[#603F9C] w-full ">
      <div className="flex flex-col lg:flex-row w-full h-full  bg-[#603F9C] text-[#ffffff] lg:gap-[185px]">
  <div className="lg:max-w-[450px] w-full lg:w-1/2 flex flex-col gap-[22px] p-[20px] lg:p-[7px] xl:p-[0px] ml-auto my-auto g:pb-[0px] lg:pt-[0px] pt-[80px] pb-[40px]">
    <h1 className="text-[40px] s:text-[43px] s:leading-[54px] font-medium">
    Collaborative Meetings
    </h1>
    <p className="text-[16px] text-[#FFFFFFB2]">
    Transform your meetings with our interactive solution. Unlike traditional video conferencing, our platform centers on interactivity, offering you the power of LLM at your fingertips. Whether it's a board presentation or a planning session, we've got you covered.
    </p>
    <SpecsBtn DownloadFileName="Online COllabarations Specs.pdf" specDownloadLink= "" rightArrow={rightArrow} />
   
    <DownloadButton DownloadFileName="Online Collabarations.pdf" brocherDownloadLink= "" downArrow={downArrow} />
   
  </div>
  <div className="lg:w-1/2 lg:w-[50%] w-[100%]">
    <img
      src={whiteBoardImg}
      alt="White-board"
      className="w-full h-full object-cover"
    />
  </div>
</div>


      </div>






      {/* animation */}
      {innerWidth > 880 && (
        <div
          id="animation"
          className={`w-full max-w-full h-full flex items-start justify-between py-[60px] gap-[20px] relative px-[20px] xl:px-[0px] text-[#000000]`}
        >
          <div className="text-container flex flex-col gap-[25px] xmd:w-[400px] xl:w-[450px]">
            <h1 className="text-[34px] font-medium">
              Bored with talking and more talking in video conferencing? How
              about a video conference that brings great minds together to your
              workspace?
            </h1>
            {/* first container */}
            <div id="first-container" className={`flex flex-col gap-[8px] `}>
              <div className="flex flex-col gap-[2px] relative">
                <div className="bg-[#603F9C] rounded-[9999px] w-[26px] h-[26px] text-[#FFFFFF] flex items-center justify-center">
                  1
                </div>
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] h-[264px]"
                />
                <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div>
              </div>
              <div className="flex flex-col gap-[8px] ml-[10px] pr-[136px]">
                <h3 className="font-normal text-[24px] text-[#302C2C]">
                  Interactive Video Conferencing
                </h3>
                <p className="text-[15px] text-[#302C2CA6]">
                  Make your video conferencing a virtual collaboration session.
                  Ideate, participate, and show your skills contributing to the
                  collaborative board. Like someone’s approach? Follow them and
                  give your inputs. Possibilities are endless, always leave your
                  mark.
                </p>
              </div>
            </div>
            {/* second container */}
            <div id="second-container" className="flex flex-col gap-[8px]">
              <div className="flex flex-col gap-[2px]">
                <div className="bg-[#603F9C] rounded-[9999px] w-[26px] h-[26px] text-[#FFFFFF] flex items-center justify-center">
                  2
                </div>
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] xmd:h-[250px] lg:h-[320px] xl:h-[440px]"
                />
                <div
                  id="second-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div>
              </div>
              <div className="flex flex-col gap-[8px] ml-[10px] pr-[136px]">
                <h3 className="font-normal text-[24px] text-[#302C2C]">
                  Interactive Breakout Rooms
                </h3>
                <p className="text-[15px] text-[#302C2CA6]">
                  How about working on an idea, a presentation etc., where
                  multiple teams need to ideate their ideas? We got you covered
                  with our interactive breakout rooms. Take material from main
                  room to breakout room, complete your work as a group and bring
                  back completed work to main room all in one session. Isn’t it
                  amazing how you can work independently yet together?
                </p>
              </div>
            </div>
            {/* third container */}
            <div id="third-container" className="flex flex-col gap-[8px]">
              <div className="flex flex-col gap-[2px]">
                <div className="bg-[#603F9C] rounded-[9999px] w-[26px] h-[26px] text-[#FFFFFF] flex items-center justify-center">
                  3
                </div>
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] xmd:h-[250px] lg:h-[320px] xl:h-[440px] "
                />
              </div>
              <div
                id="third-container-content"
                className="flex flex-col gap-[8px] ml-[10px] pr-[136px]"
              >
                <h3 className="font-normal text-[24px] text-[#302C2C]">
                  Integrated state of the art Generative AI capabilities
                </h3>
                <p className="text-[15px] text-[#302C2CA6]">
                  Powerup your interactive conferences with state-of-the-art
                  generative AI that can answer, analyze, summarize anything,
                  and keep you focused on what you are best at. Never again have
                  to guess, ask what you need, and you will have it. Bringing
                  amazing array of productivity tools at your disposal with
                  world’s best generative AI.
                </p>
              </div>
            </div>
          </div>

          <div className="relative w-full max-w-[600px] min-w-[300px]">
            <Sticky enabled={isStickyEnabled} top={60} bottomBoundary="#animation">
              <div className="image-container1 sticky top-0 flex flex-col items-center justify-center">
                <img
                  src={imageSrc}
                  alt="Changing animation"
                  id="image-element"
                  className="transition-all duration-300 ease-in-out w-[625px] h-auto"
                />
              </div>
            </Sticky>
            
          </div>
        </div>
      )}
      {/* animation section for <768 window width */}
      {innerWidth <= 880 && (
        <div className="w-full h-full  flex flex-col items-center py-[50px] s:py-[100px] s:px-[27px] xxs:px-[60px] justify-center gap-[66px]">
          <div className="flex flex-col gap-[25px]">
            <h1 className="s:text-[28px] md:text-[34px] font-medium text-center">
              Bored with talking and more talking in video conferencing? How
              about a video conference that brings great minds together to your
              workspace?
            </h1>
            {/* first container */}
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <img
                src={tableImage1}
                alt="tableImage"
                className="rounded-[14px] xxs:w-[450px]"
              />
              <div className="flex flex-col gap-[2px] relative">
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] s:h-[164px] xxs:h-[200px]"
                />
                {/* <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div> */}
              </div>
              <div className="flex flex-col gap-[8px] ">
                <h3 className="font-normal text-[24px] text-[#302C2C] text-center">
                  Interactive Video Conferencing
                </h3>
                <p className="text-[15px] text-[#302C2CA6] text-center">
                  Make your video conferencing a virtual collaboration session.
                  Ideate, participate, and show your skills contributing to the
                  collaborative board. Like someone’s approach? Follow them and
                  give your inputs. Possibilities are endless, always leave your
                  mark.
                </p>
              </div>
            </div>
            {/* second container */}
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <img
                src={tableImage2}
                alt="tableImage"
                className="rounded-[14px] xxs:w-[450px]"
              />
              <div className="flex flex-col gap-[2px] relative">
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] s:h-[164px] xxs:h-[200px]"
                />
                {/* <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div> */}
              </div>
              <div className="flex flex-col gap-[8px] ">
                <h3 className="font-normal text-[24px] text-[#302C2C] text-center">
                  Interactive Breakout Rooms
                </h3>
                <p className="text-[15px] text-[#302C2CA6] text-center">
                  How about working on an idea, a presentation etc., where
                  multiple teams need to ideate their ideas? We got you covered
                  with our interactive breakout rooms. Take material from main
                  room to breakout room, complete your work as a group and bring
                  back completed work to main room all in one session. Isn’t it
                  amazing how you can work independently yet together?
                </p>
              </div>
            </div>
            {/* third container */}
            <div className="flex flex-col gap-[8px] items-center justify-center">
              <img
                src={tableImage3}
                alt="tableImage"
                className="rounded-[14px] xxs:w-[450px]"
              />
              <div className="flex flex-col gap-[2px] relative">
                <img
                  src={verticalDivider}
                  alt="vertical Divider"
                  className="w-[24px] s:h-[164px] xxs:h-[200px]"
                />
                {/* <div
                  id="first-container-circle"
                  className="bg-[#34495E] rounded-[9999px] w-0 h-0"
                ></div> */}
              </div>
              <div className="flex flex-col gap-[8px] ">
                <h3 className="font-normal text-[24px] text-[#302C2C] text-center">
                  Integrated state of the art Generative AI capabilities
                </h3>
                <p className="text-[15px] text-[#302C2CA6] text-center">
                  Powerup your interactive conferences with state-of-the-art
                  generative AI that can answer, analyze, summarize anything,
                  and keep you focused on what you are best at. Never again have
                  to guess, ask what you need, and you will have it. Bringing
                  amazing array of productivity tools at your disposal with
                  world’s best generative AI.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* cards */}
      <div className="flex items-center justify-center w-full ">
        <div className="w-full max-w-[1570px] flex s:flex-col xmd:flex-row gap-[20px] s:px-[25px] sm:px-[50px] xmd:px-[30px] s:py-[30px] sm:py-[45px] xmd:py-[60px] text-[#ffffff] ">
          {/* first card */}
          <div className="flex flex-col s:w-full xmd:w-4/6 h-full rounded-[12px] overflow-hidden">
            <div className="bg-[#371E64] pt-[40px] pb-[86px] s:pb-[32px] sm:pb-[50px] md:h-[380px]">
            <h1 className="font-medium text-[34px] s:text-[19px] sm:text-[46px] md:px-[40px] px-[20px]">
                Ready to remove <span className="text-[#B099D9]">   HYBRID, VIRTUAL</span> from your vocabulary and make
                every meeting like in person meeting?
              </h1>
            </div>
            <div className="py-[30px] bg-[#603f9c] px-[70px] s:px-[25px] sm:px-[40px]">
              <p className="text-[17px] ">Schedule a demo</p>
              <div className="w-[160px] mt-[35px] flex flex-row gap-[9px]">
              <div
                      className="flex flex-col gap-[5px] justify-center"
                      style={{ alignItems: "flex-start" }}
                      onMouseEnter={() => setIsHoverContactSales(true)}
                      onMouseLeave={() => setIsHoverContactSales(false)}
                    >
                      <Link
                        to="/contact-us"
                        className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                      >
                        <p className="leading-[12px] font-semibold">
                          Contact Sales
                        </p>
                        <span
                          className={`${isHoverContactSales ? "bounce-x" : ""}`}
                        >
                          <img
                            src={whiteRightArrow}
                            alt="Arrow"
                            className="w-[21px] h-[12px]"
                          />
                        </span>
                      </Link>
                      <div
                        className={`h-[2px] bg-[#fff] justify-end ${
                          isHoverContactSales
                            ? "w-[80%] transition-all"
                            : "w-[100%] transition-all"
                        }`}
                        style={{ willChange: "transform" }}
                      ></div>
                    </div>
              </div>
            </div>
          </div>
          {/* second card */}
          <div className="w-full bg-cover rounded-[12px] lg:h-[520px]">
            <img
              src={secondCardImage}
              alt="second card"
              className="w-full s:h-full rounded-[10px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineCollab;
