import {Link} from 'react-router-dom';
function SpecSection(props){
    let rightArrow = props.rightArrow ? props.rightArrow : "";
    let specDownloadLink = props.specDownloadLink ? props.specDownloadLink : "";
    let DownloadFileName = props.DownloadFileName ? props.DownloadFileName : "Brocher.pdf";
    return(
        <div className="flex flex-row gap-[22px]">
        < button onClick={()=>handleClick(specDownloadLink, DownloadFileName)}
         
          className="bg-[#ffffff] px-[18px] py-[9px] gap-[10px] rounded-[6px] flex flex-row items-center hover:no-underline hover-container"
        >
          <p className="text-[#302C2C]">Spec Sheet</p>
          <div className="flex flex-row gap-[10px] relative overflow-hidden">
            <span className="absolute initial-slide-in">
              <img
                src={rightArrow}
                alt="arrow"
                className="w-[14px] h-[13px]"
              />
            </span>
            <span className="initial-slide-out">
              <img
                src={rightArrow}
                alt="arrow"
                className="w-[14px] h-[13px]"
              />
            </span>
          </div>
        </button>
         <Link
                                to="/contact-us"
                                className="flex flex-row justify-center items-center gap-[4px] hover:no-underline hover:text-inherit"
                              >
        <button className="border-2 rounded-[8px] px-[15px] py-[7px]">
          Contact sales
        </button>
        </Link>
      </div>
    )
}
function handleClick(brocherDownloadLink, DownloadFileName){
  if(brocherDownloadLink){
    const url = brocherDownloadLink;
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.download = DownloadFileName;
    link.click();
  }else{
    alert('Coming Soon...');
  }
}
export default SpecSection;